@charset "utf-8";/* CSS Document */
*{margin: 0;padding: 0;}
html {font-size: 62.5%;height: 100%}
body {margin: 0 auto;font-family:Arial, Helvetica, sans-serif;font-size: 14px;font-size: 1.4rem;height: 100%;overflow-x: hidden;max-width: 1920px;}
img {border: none;}
ul li {list-style-type: none;}
ul, form, p, a, img, table, tr, td, li, dd, dt, dl, span {margin: 0;padding: 0;list-style: none;color: #333;}
a {text-decoration: none;color: #333;outline: none;transition: 0.3s;-moz-transition: 0.3s;-o-transition: 0.3s;-webkit-transition: 0.3s;}
h1 {margin: 0;padding: 0;font-weight: normal;color: #333;}
.clear {clear: both;}
.maln {margin-left: auto !important}
input, textarea {font-family:Arial, Helvetica, sans-serif;font-size: 14px;font-size: 1.4rem;color:#333;border: none;outline: none;}
.l{ float:left;}
.r{ float:right;}
.fix{*zoom:1; } .fix:after,.fix:before{display:block; content:"clear"; height:0; clear:both; overflow:hidden; visibility:hidden; }

::-webkit-input-placeholder {/* WebKit browsers */
 color:#1b1b1b;}
:-o-placeholder {/* Mozilla Firefox 4 to 18 */
 color:#1b1b1b;}
::-moz-placeholder {/* Mozilla Firefox 19+ */
 color:#1b1b1b; opacity: 1;}
:-ms-input-placeholder {/* Internet Explorer 10+ */
 color:#1b1b1b;}
 
.wp {width: 1200px;margin: auto;}
@media screen and (min-width:1220px) {.wp {width: 1200px;margin: auto;}}
@media screen and (min-width:1000px) and (max-width:1219px) {.wp {width: 980px;margin: auto;}}
@media screen and (max-width:999px) {.wp {width: 95%;margin: auto;}}


.wp2 {width: 1350px;margin: auto;}
@media screen and (min-width:1370px) {.wp2 {width: 1350px;margin: auto;}}
@media screen and (min-width:1000px) and (max-width:1369px) {.wp2 {width: 980px;margin: auto;}}
@media screen and (max-width:999px) {.wp2 {width: 95%;margin: auto;}}

.no_margin{margin-left: auto\0!important;}
@media screen and (min-width:1220px) {.no_margin{margin-left: auto!important;}}
@media screen and (min-width:1000px) and (max-width:1219px) {.no_margin{margin-left: auto!important;}}

/*.container*/
.wap-hd-nav { display: none; }

.wap-nav { width: 5px; height: 100%; position: absolute; right: 0; -webkit-transition: all 0.4s ease 0s; -o-transition: all 0.4s ease 0s; -moz-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 999999999999999; cursor: pointer; position: fixed; top: -5px; right: 0; display: block; }

.wap-nav .nav { overflow: hidden; width: 300px; background: rgba(0, 0, 0, 0.8); position: absolute; top: 0; right: 0; display: block; height: auto; overflow: scroll; }

.wap-nav .nav span { font-family: arial, helvetica, sans-serif; }

.wap-nav .nav form { margin: 15px auto; width: 258px; height: 26px; border: 1px solid #fff; }

.wap-nav .nav form .iptc { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; padding-left: 15px; background-color: #fff; width: 80%; height: 26px; line-height: 26px; display: block; float: left; }

.wap-nav .nav form .ipsc { width: 20%; height: 26px; line-height: 26px; display: block; float: right; background: #fff url("../images/sousuo.png") no-repeat center center; }

.wap-nav .nav > ul { display: none; margin-top: 0px; margin-bottom: 0px; overflow: scroll; padding-top: 45px; -ms-box-sizing: border-box; -o-box-sizing: border-box; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.wap-nav .nav > ul > li { line-height: 40px; font-size: 12px; font-size: 1.2rem; text-indent: 20px; position: relative; }

.wap-nav .addition { width: 20px; height: 20px; position: absolute; right: 15px; top: 10px; background: url("../images/shizi.png") no-repeat center center; }

.wap-nav .addition.active { background-image: url("../images/yizi.png"); }

.wap-nav .addition.fa:before { margin-right: 5px; color: #fff; text-indent: 0; }

.wap-nav .nav > ul > li > a { margin-right: 40px; color: #fff; font-size: 12px; font-size: 1.2rem; display: block; }

.wap-nav .nav > ul > li > a span { color: #fff; font-size: 12px; font-size: 1.2rem; }

.wap-nav .nav > ul > li .c-show { display: none; width: 100%; }

.wap-nav .nav > ul > li .c-show1 .c-s1 { position: relative; padding-left: 15px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > a { position: relative; margin-right: 40px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 { position: relative; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 { position: relative; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a { margin-right: 40px; text-indent: 35px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.wap-nav .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 { position: relative; }

.wap-nav .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a { margin-right: 40px; text-indent: 50px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.wap-nav .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.wap-nav .nav > ul > dl { padding: 20px; }

.wap-nav .nav > ul > dl dd { float: left; margin: 0 2px; }

.wap-nav .menubtn { width: 23px; height: 18px; position: absolute; top: 17px; right: 12px; -webkit-transition: all 0.4s ease 0s; -o-transition: all 0.4s ease 0s; -moz-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 9999; }

.wap-nav .menubtn span { display: block; width: 100%; height: 3px; margin-bottom: 3px; background-color: #258cdb; }

@media screen and (min-width: 768px) { .wap-nav, .menubtn { display: none; } }

@media screen and (max-width: 991px) { .wap-hd-nav { display: -webkit-box; display: -webkit-flex; display: -moz-box; display: -ms-flexbox; display: flex; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -webkit-flex-flow: row nowrap; -moz-box-orient: horizontal; -moz-box-direction: normal; -ms-flex-flow: row nowrap; flex-flow: row nowrap; -webkit-box-pack: justify; -webkit-justify-content: space-between; -moz-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; background-color: #130171; }
  .wap-hd-nav-item { width: 25%; }
  .wap-hd-nav-item a { display: block; padding: 5px 0; line-height: 20px; font-size: 14px; color: white; text-align: center; background-color: #363636; }
  .wap-hd-nav-item a.active { color: white; background-color: #130171; } }


/***********  lunbo  *************/
*{margin: 0;padding:0;}
li{list-style: none;}

#wowslider-container1 { display: table; zoom: 1; position: relative; width: 100%; max-width: 1920px; max-height:620px; margin:0px auto 0px; z-index:90; text-align:left; /* reset align=center */
	font-size: 10px; text-shadow: none; 
	-webkit-box-sizing: content-box; -moz-box-sizing: content-box; box-sizing: content-box; }
* html #wowslider-container1{ width:1920px }
#wowslider-container1 .ws_images ul{ position:relative; width: 10000%; height:100%; left:0; list-style:none; margin:0; padding:0; border-spacing:0; overflow: visible; /*table-layout:fixed;*/
}
#wowslider-container1 .ws_images ul li{ position: relative; width:1%; height:100%; line-height:0; overflow: hidden; float:left;	padding:0 0 0 0 !important; margin:0 0 0 0 !important; }
#wowslider-container1 .ws_images{ position: relative; left:0; top:0; height:100%; max-height:620px; max-width: 1920px; vertical-align: top; border:none; overflow: hidden; }
#wowslider-container1 .ws_images ul a{ width:100%; height:100%; max-height:620px; display:block; color:transparent; }
#wowslider-container1 img{ max-width: none !important; }
#wowslider-container1 .ws_images .ws_list img,
#wowslider-container1 .ws_images > div > img{ width:100%; border:none 0; max-width: none; padding:0; margin:0; }
#wowslider-container1 .ws_images > div > img { max-height:620px; }
#wowslider-container1 .ws_images iframe { position: absolute; z-index: -1; }
#wowslider-container1 .ws-title > div { display: inline-block !important; }
#wowslider-container1 a{ text-decoration: none; outline: none; border: none; }
#wowslider-container1  .ws_bullets {position:absolute; z-index:70; width: 100%;text-align: center;left:0;}
/* compatibility with Joomla styles */
#wowslider-container1  .ws_bullets a { line-height: 0; }
#wowslider-container1  .ws_script{ display:none; }
#wowslider-container1 sound, 
#wowslider-container1 object{ position:absolute; }

/* prevent some of users reset styles */
#wowslider-container1 .ws_effect { position: static; width: 100%; height: 100%; }
#wowslider-container1 .ws_photoItem { border: 2em solid #fff; margin-left: -2em; margin-top: -2em; }
#wowslider-container1 .ws_cube_side { background: #A6A5A9; }


/* hide controls when video start play */
#wowslider-container1.ws_video_playing .ws_bullets,
#wowslider-container1.ws_video_playing .ws_fullscreen,
#wowslider-container1.ws_video_playing .ws_next,
#wowslider-container1.ws_video_playing .ws_prev { display: none; }
.ws-title-wrapper{ display: none; }

/* youtube/vimeo buttons */
#wowslider-container1 .ws_video_btn { position: absolute; display: none; cursor: pointer; top: 0; left: 0; width: 100%; height: 100%; z-index: 55; }
#wowslider-container1 .ws_video_btn.ws_youtube,
#wowslider-container1 .ws_video_btn.ws_vimeo { display: block; }
#wowslider-container1 .ws_video_btn div { position: absolute; background-image: url(./playvideo.png); background-size: 200%; top: 50%; left: 50%; width: 7em; height: 5em; margin-left: -3.5em; margin-top: -2.5em; }
#wowslider-container1 .ws_video_btn.ws_youtube div { background-position: 0 0; }
#wowslider-container1 .ws_video_btn.ws_youtube:hover div { background-position: 100% 0; }
#wowslider-container1 .ws_video_btn.ws_vimeo div { background-position: 0 100%; }
#wowslider-container1 .ws_video_btn.ws_vimeo:hover div { background-position: 100% 100%; }
#wowslider-container1 .ws_playpause.ws_hide { display: none !important; }
#wowslider-container1 .ws_bullets a { width:22px; height:22px; background: url(../images/dian1.png) no-repeat center center; display: inline-block; text-indent: -4000px; position:relative; margin:0 1px; color:transparent; }
#wowslider-container1 .ws_bullets a:hover{ background: url(../images/dian2.png) no-repeat center center;}
#wowslider-container1 .ws_bullets a.ws_selbull{ background: url(../images/dian2.png) no-repeat center center; }	
#wowslider-container1 a.ws_prev{position:absolute; top:50%; margin-top:-30px; z-index:60; height: 60px; width: 60px; background: url(../images/left.png) no-repeat center center;}
#wowslider-container1 a.ws_next{ position:absolute; top:50%; margin-top:-30px; z-index:60; height: 60px; width: 60px; background: url(../images/right.png) no-repeat center center; }
#wowslider-container1 a.ws_next {right:5%;}
#wowslider-container1 a.ws_prev { left:5%;}

/*playpause*/
#wowslider-container1 .ws_playpause { width: 6.4em; height: 6.4em; position: absolute; top: 50%; left: 50%; margin-left: -2.5em; margin-top: -2.5em; z-index: 59; }
#wowslider-container1 .ws_pause { background-size: 100%; background-image: url(./pause.png); }
#wowslider-container1 .ws_play { background-size: 100%; background-image: url(./play.png); }
#wowslider-container1 .ws_pause:hover, #wowslider-container1 .ws_play:hover { background-position: 100% 100% !important; }/* bottom center */
#wowslider-container1  .ws_bullets {bottom: 23px;}
#wowslider-container1 .ws-title{ position:absolute; display:block; font: 2em 'Arimo', sans-serif; bottom: -0.2em; left: 0; margin:1.8em 0.7em; padding:0.6em 0.5em; color:#777777; z-index: 50; line-height: 1em; text-transform: uppercase; color: #ffffff; 
    background-color: rgba(255, 255, 255, 0.3); border: 0.05em solid #ffffff; border-radius: 0.5em; -moz-border-radius: 0.5em; -webkit-border-radius: 0.5em; }
#wowslider-container1 .ws-title div{ margin-top: 0.3em; font-size: 0.8em; line-height: 1.15em; font-weight: normal; }
// #wowslider-container1 a.ws_next,#wowslider-container1 a.ws_prev,#wowslider-container1 .ws_playpause { display:none; }

* html #wowslider-container1 a.ws_next,* html #wowslider-container1 a.ws_prev{display:block}
#wowslider-container1:hover a.ws_next,
#wowslider-container1:hover a.ws_prev,
#wowslider-container1:hover .ws_playpause { display:block
}#wowslider-container1 .ws_images > ul{ animation: wsBasic 16s infinite; -moz-animation: wsBasic 16s infinite; -webkit-animation: wsBasic 16s infinite; }
@keyframes wsBasic{0%{left:-0%} 12.5%{left:-0%} 25%{left:-100%} 37.5%{left:-100%} 50%{left:-200%} 62.5%{left:-200%} 75%{left:-300%} 87.5%{left:-300%} }
@-moz-keyframes wsBasic{0%{left:-0%} 12.5%{left:-0%} 25%{left:-100%} 37.5%{left:-100%} 50%{left:-200%} 62.5%{left:-200%} 75%{left:-300%} 87.5%{left:-300%} }
@-webkit-keyframes wsBasic{0%{left:-0%} 12.5%{left:-0%} 25%{left:-100%} 37.5%{left:-100%} 50%{left:-200%} 62.5%{left:-200%} 75%{left:-300%} 87.5%{left:-300%} }
#wowslider-container1 .ws_bullets  a img{ text-indent:0; display:block; bottom:25px; left:-74px; visibility:hidden; position:absolute; border: 1px solid rgba(220, 220, 220, 0.6); border-radius:1px; -moz-border-radius:2px; -webkit-border-radius:2px; max-width:none; }
#wowslider-container1 .ws_bullets a:hover img{ visibility:visible; }
#wowslider-container1 .ws_bulframe div div{ height:48px; overflow:visible; position:relative; }
#wowslider-container1 .ws_bulframe div { left:0; overflow:hidden; position:relative; width:148px; background-color:#FFFFFF;}
#wowslider-container1  .ws_bullets .ws_bulframe{ display:none; bottom:29px; overflow:visible; position:absolute; cursor:pointer; -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4); box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4); border: 1px solid #FFF; border-radius:3px; -moz-border-radius:3px; }
#wowslider-container1 .ws_bulframe span{ display:block; position:absolute; bottom:-8px; margin-left:1px; left:74px; background:url(./triangle.png); width:15px; height:6px; }#wowslider-container1 .ws_bulframe div div{ height: auto; }

@media all and (max-width:767px) { 
	#wowslider-container1 .ws_fullscreen { display: block; }
	#wowslider-container1  .ws_bullets{bottom: 5px;}
	#wowslider-container1 a.ws_prev,#wowslider-container1 a.ws_next{display: none;}
}
/***********  toplx  ************/
.toplx{background: #535353;padding-top: 12px;padding-bottom: 8px;}
.yy a{margin-left: 2px;}
.yy{margin-left: 11px;}
/***********  head  ************/
.head{background: #ffffff;}
.logo{padding-top: 31px;}
.logo img{max-width: 100%;}
.navlist{padding-top: 64px;}
.navlist2{display: none;}
.navlist>li{float: left;position: relative;padding-bottom: 24px;}
.navlist>li>a{font-size: 16px;font-size: 1.6rem;font-weight: bold;display: block;padding: 0 21px;line-height: 36px;}
.navlist>li:hover>a{background: #258cdb;color: #fff;}
.navlist>li.currentnav>a{background: #258cdb;color: #fff;}

.search{position: relative;}
.sou{width: 170px;height: 28px;line-height: 28px;padding-left: 10px;background: #fff;border-radius: 14px;}
.suo{position: absolute;right: 1px;top: 1px;width: 26px;height: 26px;background: transparent;cursor: pointer;}
.search form i{position: absolute;right: 1px;top: 1px;width: 26px;height: 26px;line-height: 26px;text-align: center;font-size:14px;font-size:1.4rem;color: #666666;}

@media screen and (max-width:1369px) {
    .navlist > li > a{padding: 0 20px;}
}
@media screen and (max-width:999px) {
    .navlist > li > a{font-size:14px;font-size:1.4rem;padding: 0 15px;}
}
@media screen and (max-width:900px) {
	.navlist{width: 100%;text-align: center;padding-top: 25px;}
	.navlist > li > a{font-size:16px;font-size:1.6rem;padding: 0 10px;}
	.navlist > li{display: inline-block;float: none;margin: 0 10px;padding-bottom: 14px;}
}
@media screen and (max-width:790px) {
	.navlist > li{margin: 0 5px;}
}
@media screen and (max-width:767px) {
    .navlist2{display: block;}
    .navlist1{display: none;}
    .navlist>li{width: 25%;margin: 0;text-align: center;float: left;}
	.navlist > li > a{padding: 0;}
	.logo{display: block;width: auto;max-width: 186px;margin: 0 auto;float: none;padding-top: 14px;}
	.search{display: none;}
	.yy{float: left;}
}
@media screen and (max-width:550px) {
	.navlist > li > a{font-size:14px;font-size:1.4rem;}
	.navlist{padding-top: 16px;}
}
@media screen and (max-width:400px) {
	.navlist > li > a{font-size: 12px;font-size: 1.2rem;line-height: 32px;}
	.navlist > li{padding-bottom: 4px;}
}

/***********  syp  ************/
.syp{padding-top: 57px;padding-bottom: 84px;}

.sybt{text-align: center;font-size: 34px;font-size: 3.4rem;font-family: 'PoppinsSemiBold';}

.sybt a:hover{color: #0e5bab;}

#outer { padding-top: 35px; }

#outer #tab { text-align: center; background: url(../images/sypxian.jpg) repeat-x center center;}

#outer #tab li { display: inline-block; border: 1px solid #999999;background: #fff;width: 180px;line-height: 40px;border-radius: 20px;text-align: center; font-size: 18px; font-size: 1.8rem;cursor: pointer;margin: 0 38px; }

#outer #tab li.current { background: #0e5bab; color: #fff; border: 1px solid #0e5bab;}

#outer #tab li:hover { background: #0e5bab; color: #fff; border: 1px solid #0e5bab;}

#outer .ul { display: none; padding-top: 61px; }

.plunbo .img-scroll { position: relative; }

.plunbo .prev, .plunbo .next { position: absolute; display: block; top: 45%; margin-top: -27px; text-align: center; }

.plunbo .prev i, .plunbo .next i { font-size: 36px; font-size: 3.6rem; color: #2ba3b3; }

.plunbo .prev i:hover, .plunbo .next i:hover { color: #0b74be; }

.plunbo .prev { left: 0; cursor: pointer; }

.plunbo .next { right: 0; cursor: pointer; }

.plunbo .img-list { position: relative; overflow: hidden; margin: 0 50px; }

.plunbo .img-list ul { width: 10000%; }

.plunbo .img-list li { float: left; display: inline; width: 349px; margin-right: 26px; position: relative; position: relative; text-align: center; }

.plunbo .img-list li img { width: 100%; max-width: 486px; max-height: 370px; display: block; -webkit-transition: 0.5s; -o-transition: 0.5s; -moz-transition: 0.5s; transition: 0.5s; }

.sypbox { position: relative; }

.syptu { display: block; border: 1px solid #cccccc; overflow: hidden; }

.sypp{display: block;font-size:16px;font-size:1.6rem;line-height: 26px;margin-top: 29px;}

.sypp:hover{color: #0e5bab;}

.sypbox:hover .syptu img { -webkit-transform: scale(1.1); -moz-transform: scale(1.1); -ms-transform: scale(1.1); -o-transform: scale(1.1); transform: scale(1.1); }

@media screen and (max-width:1219px) {
	#outer #tab li{font-size:16px;font-size:1.6rem;margin: 0 20px;}
	.plunbo .img-list li{width: 276px;}
}
@media screen and (max-width:999px) {
	#outer #tab{background: none;}
	#outer #tab li{width: 46%;margin: 0 1%;margin-bottom: 5px;}
	.sybt{font-size: 30px;font-size: 3rem;}
	#outer .ul{padding-top: 40px;}
	.plunbo .img-list li{width: 0.31333%;margin: 0 0.01%;}
	.syp{padding-top: 40px;padding-bottom: 50px;}
}
@media screen and (max-width:767px) {
	.plunbo .img-list li{width: 0.48%;}
	#outer{padding-top: 20px;}
	.sypp{margin-top: 15px;}
}
@media screen and (max-width:650px) {
	#outer #tab li{line-height: 34px;}
	#outer .ul{padding-top: 30px;}
	.plunbo .img-list{margin: 0 40px;}
	.sybt{font-size:24px;font-size:2.4rem;}
}
@media screen and (max-width:500px) {
	#outer #tab li{font-size:14px;font-size:1.4rem;}
}
@media screen and (max-width:400px) {
	#outer #tab li{width: 96%;}
	.plunbo .img-list li{width: 0.98%;}
	.syp{padding-top: 30px;padding-bottom: 35px;}
}
/*************  sya  *************/
.sya{padding-top: 64px;padding-bottom: 90px;background: url(../images/sybg1.jpg) no-repeat center top;}
.sya .sybt{font-family: "POPPINS-BOLD";margin-bottom: 41px;}

.syabox{width: 392px;float: left;margin-left: 12px;}
.syatu{display: block;margin-bottom: 8px;}
.syatu img{display: block;width: 100%;max-width: 392px;max-height: 360px;}
.syaxia{background: #fff;padding: 0 40px;padding-top: 15px;padding-bottom: 20px;text-align: center;}

.syap1{display: inline-block;line-height: 37px;font-size:20px;font-size:2rem;font-family: "POPPINS-BOLD";border-top: 3px solid #d6d6d6;border-bottom: 3px solid #d6d6d6;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.syap2{display: block;font-size:16px;font-size:1.6rem;line-height: 26px;height: 52px;overflow: hidden;margin-top: 9px;margin-bottom: 13px;}
.syabox2 .syap2{height: 78px;}
.syabox2 .syaxia{margin-bottom: 10px;padding-top: 24px;padding-bottom: 26px;}
.syap3{display: inline-block;min-width: 153px;height: 40px;line-height: 40px;text-align: center;box-sizing: border-box;padding: 0 10px;background: #0759a2;color: #fff;font-size:16px;font-size:1.6rem;overflow: hidden; position: relative;}
.syap1:hover{color: #0759a2;}
.syap2:hover{color: #0759a2;}

.syap3 span { position: relative; z-index: 2; color: #fff;}

.syap3:after { position: absolute; content: ''; left: 0; top: 0; bottom: 0; width: 0; background-color: #000; -webkit-transition: all .5s; -o-transition: all .5s; -moz-transition: all .5s; transition: all .5s; font-size: 0; }

.syap3:hover:after { width: 100%; }


@media screen and (max-width:1219px) {
	.syabox{width: 318px;}
}
@media screen and (max-width:999px) {
	.syabox{width: 31.333%;margin: 0 1%;}
	.syaxia{padding-left: 15px;padding-right: 15px;}
	.sya{padding-top: 40px;padding-bottom: 55px;}
	.sya .sybt{margin-bottom: 25px;}
	.syap1{font-size:16px;font-size:1.6rem;}
	.syap2{font-size:14px;font-size:1.4rem;}
}
@media screen and (max-width:800px) {
	.syabox{width: 48%;margin-bottom: 10px;}
	.sya{background: url(../images/sybg2.jpg) no-repeat center top;padding-bottom: 35px;}
}
@media screen and (max-width:400px) {
	.syabox{width: 98%;}
	.sya{background-size: 100% 100%;}
}

/************  syn  ***********/

.synmc{padding-top: 65px;padding-bottom: 91px;}

#outer2 #tab2 { text-align: center;margin-bottom: 50px;}

#outer2 #tab2 li { display: inline-block;background: #fff;padding: 0 28px;text-align: center; font-size:24px;font-size:2.4rem;color: #666666;cursor: pointer;margin: 0 98px; font-family: "POPPINS-BOLD";border-bottom: 4px solid transparent;padding-bottom: 6px;}

#outer2 #tab2 li.current2 {border-bottom: 4px solid #f0f0f0;}

#outer2 #tab2 li:hover {border-bottom: 4px solid #f0f0f0;}

#outer2 .ul2 { display: none;}


.nbox { width: 370px; float: left; margin-left: 45px; position: relative; }

.nbox img { width: 100%; max-width: 370px; max-height: 269px; display: block; }

.np1 { font-size: 16px; font-size: 1.6rem; line-height: 24px; font-weight: bold; color: #333333; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; display: block; margin-top: 20px; margin-bottom: 10px; }

.np2 { display: block; font-size:16px;font-size:1.6rem; color: #333333; line-height: 24px; height: 48px; overflow: hidden; }

.np1:hover { color: #0759a2; }

.np2:hover { color: #0759a2; }

.fydate { width: 78px; height: 78px; background: url(../images/sjbg.png); text-align: center; position: absolute; left: 0; top: 0; }

.fydate div { font-size: 50px; font-size: 5rem; color: #fff; padding-top: 5px; line-height: 50px; }

.fydate p { font-size: 14px; font-size: 1.4rem; color: #fff; }

@media screen and (max-width: 1219px) { .nbox { margin-left: 25px; width: 310px; }
	#outer2 #tab2 li{margin: 0 40px;font-size:22px;font-size:2.2rem;}
}

@media screen and (max-width: 999px) { .nbox { width: 31.333%; margin: 0 1%; }
  .fydate { width: 68px; height: 68px; }
  .fydate div { font-size: 34px; font-size: 3.4rem; line-height: 40px; }
  #outer2 #tab2 li{font-size:20px;font-size:2rem;margin: 0 20px;}
  .synmc{padding-top: 50px;padding-bottom: 60px;}
}
@media screen and (max-width: 767px) { .np1 { font-size: 14px; font-size: 1.4rem; }
  .np2 { font-size: 14px; font-size: 1.4rem; }
  .fydate div { font-size: 30px; font-size: 3rem; line-height: 34px; }
  .fydate p { font-size: 12px; font-size: 1.2rem; }
  .fydate { width: 60px; height: 60px; } 
  #outer2 #tab2{margin-bottom: 35px;}
  #outer2 #tab2 li{font-size:18px;font-size:1.8rem;padding: 0 15px;}
}
@media screen and (max-width: 600px) { 
	#outer2 #tab2 li{width: 31.333%;margin: 0;padding: 0;padding-bottom: 10px;}
}
@media screen and (max-width: 550px) { .nbox { float: none; width: auto; max-width: 370px; margin: 0 auto; margin-bottom: 20px; }
  .synmc{padding-bottom: 25px;}
}
@media screen and (max-width: 450px) { 
	#outer2 #tab2 li{width: 98%;margin-bottom: 10px;}
	#outer2 #tab2{margin-bottom: 15px;}
	.synmc{padding-top: 35px;}
}

.mbox{width: 377px;float: left;margin-left: 34px;text-align: center;}
.mtu img{display: block;width: 100%;max-width: 377px;max-height: 268px;}
.mp1{display: block;font-size:16px;font-size:1.6rem;line-height: 26px;margin-top: 20px;}
.mp1:hover{color: #0759a2;}
.sylxzuo img{display: block;width: 100%;max-width: 526px;max-height: 348px;}
.sylxzuo{margin-right: 64px;}
.sylxyou{overflow: hidden;padding-top: 30px;}
.sylxyou strong{font-size: 26px;font-size: 2.6rem;text-transform: uppercase;display: block;line-height: 30px;margin-bottom: 24px;}
.sylxyou ul li{font-size:16px;font-size:1.6rem;line-height: 40px;}
.sylxyou ul li span{float: left;}
.sylxyou ul li span i{font-size:24px;font-size:2.4rem;color: #999999;margin-right: 15px;vertical-align: middle;}
.sylxyou ul li p{overflow: hidden;}
.sylxyou ul li a:hover{color: #0759a2;}

@media screen and (max-width:1219px) {
	.mbox{margin-left: 25px;width: 310px;}
	.sylxzuo{width: 450px;margin-right: 35px;}
	.sylxyou ul li{line-height: 30px;}
	.sylxyou strong{font-size:22px;font-size:2.2rem;}
}
@media screen and (max-width:999px) {
	.mbox{width: 31.333%;margin: 0 1%;}
	.sylxyou{padding-top: 10px;}
	.sylxzuo{width: 390px;}
	.sylxyou strong{margin-bottom: 12px;}
}
@media screen and (max-width:800px) {
	.sylxzuo{float: none;width: auto;max-width: 526px;margin: 0 auto;margin-bottom: 10px;}
}
@media screen and (max-width:550px) {
	.mbox{float: none;width: auto;max-width: 377px;margin: 0 auto;margin-bottom: 25px;}
	.sylxyou strong{font-size:18px;font-size:1.8rem;}
}
@media screen and (max-width:400px) {
	.mp1{margin-top: 15px;}
}

/*************  bottom  ************/
.bottom{background: #666666;padding-top: 42px;padding-bottom: 37px;}
.bottom p,.bottom li,.bottom a,.bottom span{color: #c5c5c5;}
.bottom a:hover{color: #258cdb;}
.div>a,.div>span{display: block;font-size:16px;font-size:1.6rem;font-weight: bold;color: #fff;margin-bottom: 17px;}

.div1{border-left: 1px solid #868686;}
.div1,.div2,.div3,.div4,.div5{width: 120px;padding:0 25px;border-right: 1px solid #868686;min-height: 195px;}
.div1 li,.div2 li,.div3 li,.div4 li,.div5 li{line-height: 24px;}
.div6{width: 296px;}
.div6 li{line-height: 24px;}

.copyright,.support{line-height: 24px;color: #666666;margin-right: 270px;}
.copyright a{color: #666666}
.copyright a:hover{color: #258cdb;}
.cnzz{padding-left: 10px;}

.dibu{background: #363636;padding-top: 15px;padding-bottom: 18px;}
.link{line-height: 24px;margin-right: 270px;}
.link a{padding: 0 20px;}
.link a,.link span{display: inline-block;color: #666666;}
.link a:hover{color: #258cdb;}

.key{line-height: 24px;margin-right: 270px;}
.key a{padding: 0 20px;}
.key a,.key span{display: inline-block;color: #666666;}
.key a:hover{color: #258cdb;}

.dibu .wp{position: relative;}
.bsjj{position: absolute;right: 0;top: 3px;}
.bsjj a{margin-left: 4px;transition: 0.5s;}

@media screen and (min-width:1000px) and (max-width:1219px) {
	.div1, .div2, .div3, .div4, .div5{padding: 0 15px;}
	.div6{width: 208px;}
}
@media screen and (max-width:999px) {
	.div{width: 33.333%;margin: 0;padding: 0;}
	.div1, .div2, .div3, .div4, .div5{border: none;min-height: 170px;}
}
@media screen and (max-width:767px) {
    .bottom{display: none;}
	.link{display: none;}
	.dibu{padding-bottom: 60px;}
	.bsjj{position: static;}
	.copyright, .support{margin-right: 0;}
	.link,.key{margin-right: 0;}
	.bsjj a{margin-left: 0;margin-right: 5px;}
}

/************  bread  *************/
.mianbao { font-size: 16px; font-size: 1.6rem; line-height: 30px; color: #333333; padding-top: 19px; padding-bottom: 18px; }

.mianbao a { color: #333333; }

.mianbao a i { font-size: 28px; font-size: 2.8rem; color: #0759a2; margin-right: 5px; vertical-align: middle; }

.mianbao a:hover { color: #0759a2; }

.co00 { color: #0759a2 !important; }

.bannerf img { display: block; width: 100%; max-width: 1920px; }

.fymain { padding-top: 7px; padding-bottom: 100px;}

/***********  about1  ************/

.afl { background: #ffffff; box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);border-top: 1px solid #e6e6e6;border-bottom: 1px solid #e6e6e6;margin-bottom: 61px;}

.afl li { float: left; width: 196px; margin-left: 306px;text-align: center; padding-top: 14px; padding-bottom: 1px; position: relative; }

.afl li img { position: absolute; left: 50%; margin-left: -12px; bottom: -12px; display: none; }

.afl li a { display: block; font-size: 18px; font-size: 1.8rem; font-weight: bold; color: #666666; line-height: 30px; padding-top: 48px; }

.afl1 a { background: url(../images/abiao1.png) no-repeat center top; }

.afl2 a { background: url(../images/abiao2.png) no-repeat center top; }

.afl3 a { background: url(../images/abiao3.png) no-repeat center top; }

.afl1:hover a { background: url(../images/abiao1h.png) no-repeat center top; color: #fff; }

.afl2:hover a { background: url(../images/abiao2h.png) no-repeat center top; color: #fff; }

.afl3:hover a { background: url(../images/abiao3h.png) no-repeat center top; color: #fff; }

.afl1.current a { background: url(../images/abiao1h.png) no-repeat center top; color: #fff; }

.afl2.current a { background: url(../images/abiao2h.png) no-repeat center top; color: #fff; }

.afl3.current a { background: url(../images/abiao3h.png) no-repeat center top; color: #fff; }

.afl li:hover { background: #275ca6; }

.afl li.current { background: #275ca6; }

.afl li:hover img { display: block; }

.afl li.current img { display: block; }

.about1{padding-bottom: 0!important;}

.ayou img{display: block;width: 100%;max-width: 674px;}
.ayou{margin-left: 50px;}
.ap1{line-height: 98px;font-size: 30px;font-size: 3rem;font-weight: bold;background: url(../images/ap1bg.png) no-repeat left center;padding-left: 31px;margin-bottom: 12px;}
.ap2{font-size:16px;font-size:1.6rem;line-height: 34px;}

.ayou2{margin-right: 33px;padding-top: 14px;}
.ayou2 img{display: block;width: 100%;max-width: 812px;}
.azuo2{padding-top: 25px;}

@media screen and (max-width:1219px) {
	.ayou{width: 500px;margin-left: 30px;}
	.ayou2{width: 580px;}
	.afl li{margin-left: 196px;}
	.afl{margin-bottom: 40px;}
}
@media screen and (max-width:999px) {
	.ayou{float: none;width: auto;max-width: 674px;margin: 0 auto;margin-bottom: 20px;}
	.ayou2{width: auto;float: none;max-width: 812px;margin: 0 auto;}
	.afl li{width: 33.333%;margin: 0;}
	.fymain{padding-bottom: 60px;}
}
@media screen and (max-width:767px) {
	.ap1{line-height: 30px;background: none;padding-left: 0;font-size:24px;font-size:2.4rem;}
	.afl li a{font-size:16px;font-size:1.6rem;}
	.afl{margin-bottom: 25px;}
	.fymain{padding-bottom: 40px;}
}
@media screen and (max-width:550px) {
	.ap1{font-size:20px;font-size:2rem;}
	.ap2{font-size:14px;font-size:1.4rem;line-height: 28px;}
	.afl li a{font-size:14px;font-size:1.4rem;}
}
@media screen and (max-width:450px) {
	.afl li.current img{display: none!important;}
	.afl li{width: 100%;}
	.fymain{padding-top: 10px;}
}

/**********  service1  **********/
.subNav1{height:42px;line-height: 42px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; cursor:pointer;background:#eeeeee url("../images/jia.png") no-repeat right center;margin-bottom: 22px;}
.subNav1 a{padding-left: 15px;display: block;height:42px;line-height: 42px;color: #333333;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; padding-right: 35px;font-size:18px;font-size:1.8rem;}
.subNav1.currentDt1{background:#eeeeee url("../images/jian.png") no-repeat right center;}
.subNav1.currentDt1 a{color: #0759a2;}
.navContent1{display: none;line-height: 30px;padding-bottom: 36px;padding-left: 15px;font-size:16px;font-size:1.6rem;margin-top: -4px;}
.navContent1 span{color: #258cdb;}

@media screen and (max-width:999px) {
	.subNav1 a{font-size:16px;font-size:1.6rem;}
	.navContent1{font-size:14px;font-size:1.4rem;}
}
@media screen and (max-width:767px) {
	.subNav1 a{font-size:14px;font-size:1.4rem;}
	.subNav1{margin-bottom: 12px;}
	.navContent1{padding-left: 5px;padding-bottom: 20px;}
}

/**********  service2  **********/
.sp1{font-size:16px;font-size:1.6rem;line-height: 26px;margin-bottom: 26px;}
.sptu{margin-bottom: 41px;margin-top: 40px;}
.sptu img{width: 48%;display: block;max-width: 576px;}

@media screen and (max-width:999px) {
	.sp1{font-size:14px;font-size:1.4rem;margin-bottom: 18px;}
	.sptu{margin-top: 25px;margin-bottom: 30px;}
}
@media screen and (max-width:500px) {
	.sptu{margin-bottom: 10px;}
	.sptu img{width: 100%;margin-bottom: 15px;}
}
/**********  service3  **********/
.downbox { background: #efefef; margin-bottom: 46px; }

.downcon { padding-left: 14px; padding-top: 28px; position: relative; padding-bottom: 29px; padding-right: 30px; }

.downtu img { display: block; width: 100%; max-width: 169px; max-height: 115px; }

.downtu { display: block; margin-right: 21px; }

.downnr { padding-top: 5px; margin-right: 155px; overflow: hidden; }

.downp1 { display: block; font-size: 18px; font-size: 1.8rem;color: #333333; line-height: 26px; margin-bottom: 17px; overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}

.downp2 { display: block; font-size: 16px; font-size: 1.6rem; color: #4d4d4d; line-height: 26px; height: 52px;overflow: hidden;}

.downp3 { display: block; width: 140px; height: 40px; line-height: 40px; text-align: center; font-weight: bold; background: #0775bf; color: #fff; position: absolute; right: 30px; top: 69px; border-radius: 5px; text-transform: uppercase; }

.downp3 img { vertical-align: middle; margin-left: 5px; }

.downp1:hover { color: #0775bf; }

.downp2:hover { color: #0775bf; }

.service3 .ms_ye { margin-top: 92px; }

.downp3:hover { background: #0965a5; }

@media screen and (max-width: 999px) { .downnr { margin-right: 165px; padding-top: 0; }
  .downbox { margin-bottom: 35px; } }

@media screen and (max-width: 767px) { .downp1 { font-size: 16px; font-size: 1.6rem; line-height: 26px; }
  .downp2 { font-size: 14px; font-size: 1.4rem; }
  .downnr { margin-right: 0; }
  .downcon { padding-bottom: 60px; }
  .downp3 { top: auto; bottom: 10px; }
  .service3 .ms_ye { margin-top: 45px; } }

@media screen and (max-width: 500px) { .downcon { padding-left: 10px; padding-right: 10px; }
  .downtu { margin-right: 15px; } }

@media screen and (max-width: 400px) { .downtu { width: auto; margin: 0 auto; width: 169px; float: none; margin-bottom: 15px; } }



/**********  contact  **********/
/***************  contact  **************/
.ditu { margin-top: 53px; }

.ditu img { display: block; width: 100%; max-width: 1200px; max-height: 419px; }

.ditu2 img { display: block; width: 100%; max-width: 1197px; max-height: 370px; }

.huibg { background: #f2f2f2 url(../images/xuditu.png) no-repeat center top; padding-left: 23px; padding-right: 23px; padding-top: 44px; padding-bottom: 52px;margin-bottom: 49px; border-radius: 10px; }

.huizuo { width: 50%; }

.huiyou { width: 45%; }

.huibg > p { text-align: center; font-size: 26px; font-size: 2.6rem; font-weight: bold; color: #333333; text-transform: uppercase; margin-bottom: 50px; }

.huizuo ul li { font-size: 16px; font-size: 1.6rem; line-height: 40px; max-width: 95%; }

.huizuo ul li i { font-size: 24px; font-size: 2.4rem; color: #999999; float: left; margin-right: 5px; }

.huizuo ul li p { overflow: hidden; }

.huizuo ul li a:hover{color: #0759a2;}

.xinl { width: 462px; float: left; }

.xinr { width: 656px; float: right; }

.write { font-size: 16px; font-size: 1.6rem; line-height: 34px; margin-bottom: 21px; color: #666666;}

.write span { font-size: 26px; font-size: 2.6rem; font-weight: bold; text-transform: uppercase; margin-right: 5px; vertical-align: middle; }

.cuxin .int1 { padding-left: 16px; margin-top: 19px; position: relative; }

.cuxin .int1 i, .cuxin .int2 i { font-size: 20px; font-size: 2rem; color: #999999; position: absolute; left: 25px; top: 13px; }

.cuxin .xing { background: url(../images/xing.jpg) no-repeat left 22px; }

.cuxin .int1 input { width: 405px; height: 48px; border: 1px solid #aaa; padding-left: 37px; line-height: 48px; font-size: 16px; font-size: 1.6rem; }

.cuxin .int2 { padding-left: 16px; margin-top: 19px; position: relative; }

.cuxin .int3 { padding-left: 16px; margin-top: 14px; }

.cuxin textarea { width: 598px; height: 186px; border: 1px solid #aaa; padding-left: 40px; line-height: 44px; font-size: 16px; font-size: 1.6rem; }

.cuxin .int4 { width: 230px; height: 48px; border: 1px solid #aaa; padding-left: 11px; line-height: 48px; float: left; font-size: 16px; font-size: 1.6rem; }

.cuxin .cuyanzheng { width: 161px; height: 50px; float: left; margin-left: 8px; cursor: pointer; }

.cuxin .int5 { width: 184px; height: 50px; border: none; background: #666666; float: right; cursor: pointer; color: #fff; padding-left: 0; text-transform: uppercase; font-size: 16px; font-size: 1.6rem; }

.cuxin .int5:hover { background: #0759a2; }

@media screen and (max-width: 1219px) { .cuxin .int1 input { width: 355px; }
  .cuxin textarea { width: 492px; }
  .cuxin .int4 { width: 135px; }
  .cuxin .int5 { width: 192px; }
  .xinl { width: 410px; }
  .xinr { width: 550px; } }

@media screen and (max-width: 999px) { .cuxin .int1 input { width: -webkit-calc(100% - 37px); width: -moz-calc(100% - 37px); width: calc(100% - 37px); }
  .cuxin textarea { width: -webkit-calc(100% - 40px); width: -moz-calc(100% - 40px); width: calc(100% - 40px); }
  .cuxin .int4 { width: 180px; }
  .xinl { width: 100%; }
  .xinr { width: 100%; }
  .huibg > p { font-size: 24px; font-size: 2.4rem; }
  .huizuo ul li { line-height: 36px; } }

@media screen and (max-width: 767px) { .cuxin .int4 { width: -webkit-calc(100% - 11px); width: -moz-calc(100% - 11px); width: calc(100% - 11px); margin-bottom: 18px; }
  .cuxin .cuyanzheng { margin-left: 0; }
  .huizuo { width: 100%; }
  .huizuo ul li { max-width: 100%; font-size: 14px; font-size: 1.4rem; }
  .ditu { margin-top: 30px; }
  .huibg{margin-bottom: 25px;padding-bottom: 30px;} 
  .write{margin-bottom: 5px;}
}

@media screen and (max-width: 650px) { .huibg > p { font-size: 20px; font-size: 2rem; margin-bottom: 20px; }
  .write span { font-size: 24px; font-size: 2.4rem; } }

@media screen and (max-width: 400px) { .cuxin .int5 { width: 100%; margin-top: 19px; } }






@media screen and (max-width:1219px) {
	
}
@media screen and (max-width:999px) {
	
}
@media screen and (max-width:767px) {
	
}
/****************  abg  ****************/
.abg{background: url(../images/abg.jpg) repeat-y center top;margin-top: 65px;}
.abg li{float: left;margin-left: 30px;width: 430px;background: url(../images/aibg.png);height: 412px;text-align: center;transition: 0.5s;}
.abg li div{padding: 0 45px;padding-top: 144px;transition: 0.5s;}
.abg li strong{display: block;font-size: 30px;font-size: 3rem;padding-top: 137px;}
.abg li p{font-size:18px;font-size:1.8rem;line-height: 30px;margin-top: 10px;}
.ali1 strong{background: url(../images/ai1.png) no-repeat center top;}
.ali2 strong{background: url(../images/ai2.png) no-repeat center top;}
.ali3 strong{background: url(../images/ai3.png) no-repeat center top;}

.abg li:hover{background: #275ca6;}
.ali1:hover strong{background: url(../images/ai1h.png) no-repeat center top;}
.ali2:hover strong{background: url(../images/ai2h.png) no-repeat center top;}
.ali3:hover strong{background: url(../images/ai3h.png) no-repeat center top;}
.abg li:hover strong{color: #fff;}
.abg li:hover p{color: #fff;}
.abg li:hover div{padding-top: 86px;}

@media screen and (max-width:1369px) {
	.abg li{width: 313px;margin-left: 20px;}
	.abg li strong{font-size:22px;font-size:2.2rem;}
	.abg li div{padding-left: 22px;padding-right: 22px;padding-top: 110px;}
	.abg li p{font-size:16px;font-size:1.6rem;}
}
@media screen and (max-width:999px) {
	.abg li{width: 31.333%;margin: 0 1%;height: 380px;}
	.abg li strong{font-size:18px;font-size:1.8rem;}
	.abg{margin-top: 30px;}
	.abg li div{padding-top: 80px;}
}
@media screen and (max-width:767px) {
	.abg li p{font-size:14px;font-size:1.4rem;line-height: 26px;}
	.abg li div{padding-top: 30px;padding-left: 12px;padding-right: 12px;}
	.abg li{height: 250px;}
	.abg li:hover div{padding-top: 30px;}
	.abg li strong{background-size: 60px;padding-top: 90px;}
}
@media screen and (max-width:700px) {
	.abg li{width: 100%;margin: 0;height: auto;margin-bottom: 10px;}
	.abg li div{padding-bottom: 20px;}
	.abg li strong{padding-top: 72px;}	
}


.about1-his .img-scroll { position: relative; padding: 0 63px; margin-top: 34px; }

.about1-his .img-scroll .prev, .about1-his .img-scroll .next { position: absolute; display: block; width: 30px; height: 30px; line-height: 30px; text-align: center; border-radius: 50%; border: 1px solid #8b8b8b; top: 50%; margin-top: -16px; }

.about1-his .img-scroll .prev { left: 0; cursor: pointer; }

.about1-his .img-scroll .next { right: 0; cursor: pointer; }

.about1-his .img-list { position: relative; overflow: hidden; padding: 0 7px; }

.about1-his .img-list ul { width: 10000%; background: url(../images/about6.png) repeat-x left center; }

.about1-his .img-list li { width: 0.5%; float: left; display: inline; margin-right: 7px; }

.about1-his .img-list li .item-body { width: 50%; padding-left: 16px; border-left: 1px solid #7d7d7d; position: relative; }

.about1-his .img-list li .item-desc { line-height: 26px; height: 104px; overflow: hidden; font-size: 16px; font-size: 1.6rem; }

.about1-his .img-list li .item-date { font-size: 24px; font-size: 2.4rem; line-height: 26px; margin: 0px 0 6px; color: #333333; }

.about1-his .img-list li .item-body.l img { position: absolute; left: -7px; bottom: -7px; }

.about1-his .img-list li .item-body.r img { position: absolute; left: -7px; top: -7px; }

.about1-his .img-list li .item-body.r .item-date { padding-top: 30px; }

.about1-his .img-list li .item-body.l .item-desc { padding-bottom: 30px; }

@media screen and (max-width: 1419px) { .about1-his .img-list li .item-date { font-size: 24px; font-size: 2.4rem; } }

@media screen and (max-width: 1219px) { .about1-his .img-list li .item-date { font-size: 20px; font-size: 2rem; }
  .about1-his .img-list li .item-desc { font-size: 14px; font-size: 1.4rem; line-height: 26px; } }

@media screen and (max-width: 767px) { .about1-his .img-list li { width: 1%; margin-right: 14px; } }

@media screen and (max-width: 450px) { .about1-his .img-scroll { padding: 0 40px; }
  .about1-his .img-list li .item-date { font-size: 16px; font-size: 1.6rem; }
  .about1-his .img-list li .item-body { width: 70%; } }


.als{background: #eeeeee;padding-top: 80px;padding-bottom: 50px;}
.abt{text-align: center;font-size: 30px;font-size: 3rem;font-weight: bold;}

.customer{padding-top: 58px;padding-bottom: 0px;background: #fff;}
.ahang{position: relative;min-height: 355px;margin-bottom: 50px;}
.ahang img{display: block;width: 44.53125%;max-width: 855px;}
.ahang .wp div{width: 53.333%;}

.ahang1 img{position: absolute;left: 0;top: 0;}
.ahang .wp div strong{font-size: 30px;font-size: 3rem;padding-left: 23px;background: url(../images/hui.jpg) no-repeat left 5px;display: block;margin-bottom: 20px;}
.ahang .wp div p{font-size:16px;font-size:1.6rem;color: #575655;line-height: 30px;}
.ahang2 img{position: absolute;right: 0;top: 0;}

@media screen and (max-width:1219px) {
	.ahang .wp div p{font-size:14px;font-size:1.4rem;line-height: 26px;}
	.ahang{min-height: 230px;}
}
@media screen and (max-width:999px) {
	.ahang img{position: static;margin: 0 auto;width: 100%;}
	.ahang .wp div{width: 100%;margin-top: 20px;}
	.als{padding-top: 55px;}
}
@media screen and (max-width:767px) {
	.abt{font-size:24px;font-size:2.4rem;}
	.ahang .wp div strong{font-size:24px;font-size:2.4rem;}
	.ahang .wp div strong{background: url(../images/hui.jpg) no-repeat left 2px;}
	.ahang{margin-bottom: 30px;}
}
@media screen and (max-width:500px) {
	.customer{padding-top: 35px;}
}

/**************  sypro  ************/
.sypro { padding-top: 86px; padding-bottom: 70px; background: #eeeeee;}

.sypro .abmain5 { margin-top: 60px;}

.sypro #box { position: relative; width: 1060px; height: 510px; margin: auto; margin-top: 30px; }

.sypro #box ul { position: relative; width: 1060px; height: 430px; }

.sypro #box li { position: absolute; list-style: none; width: 0; height: 0; top: 0px; left: 0px; z-index: 0; }

.sypro #box li img { width: 100%; height: 100%; vertical-align: top; }

.sypro #box li div { width: 100%; line-height: 30px; margin-top: 23px; color: #333333; text-align: center; font-size:16px;font-size:1.6rem; }

.sypro #box .prev, .sypro #box .next { position: absolute; top: 50%; margin-top: -50px; overflow: hidden; cursor: pointer; }

.sypro #box .prev { left: -70px; }

.sypro #box .next { right: -70px; }

.sypro #box .prev i, .sypro #box .next i { font-size: 38px; font-size: 3.8rem; color: #cccccc; }

.sypro #box .prev i:hover, .sypro #box .next i:hover { color: #000000; }

.sypro #copyright { padding-top: 10px; text-align: center; }

.sypro .heitu { position: absolute; width: 100%; left: 0px; top: 0px; }

.sypro .heitu img { display: block; margin: auto; width: 100%; max-width: 620px; max-height: 450px; }

.sypro #box li img { box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }

.sypro #box2 { display: none; }

@media screen and (max-width: 1219px) { .sypro .wp > ul li { width: 480px; }
  .sypro #box { width: 100%; }
  .sypro #box ul { width: 100%; }
  .sypro #box { display: none; }
  .sypro #box2 { display: block; }
  .sypro #box2 li { float: left; margin-left: 10px; width: 314px; margin-top: 20px; }
  .sypro #box2 li img { width: 100%; max-width: 610px; max-height: 457px; }
  .sypro #box2 li div { text-align: center; font-size: 16px; font-size: 1.6rem; line-height: 30px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; margin-top: 10px;} 
  .sypro .abmain5{margin-top: 30px;}
}

@media screen and (max-width: 999px) { .sypro .wp > ul li { width: 100%; float: none; border: none; padding-top: 0; padding-bottom: 0; }
  .sypro #box2 li { width: 31%; margin: 1% !important; } 
  .sypro{padding-top: 50px;padding-bottom: 50px;}
}
@media screen and (max-width: 767px) { .sypro #box2 li { width: 48%; }
  .sypro .abmain5 { margin-top: 20px; } }

@media screen and (max-width: 550px) { 
  .sypro { padding-top: 35px; } 
  .sypro #box2 li div{font-size:14px;font-size:1.4rem;margin-top: 5px;}
}

/***************  about2  ***************/

.zsbox { width: 246px; float: left; margin-left: 72px; margin-bottom: 39px; text-align: center; }

.zscon { position: relative; overflow: hidden; }

.zsbox img { display: block; width: 100%; max-width: 246px; max-height: 334px; }

.zscover { position: absolute; left: 0; top: -100%; width: 100%; height: 100%; background: url(../images/zscover.png); text-align: center; }

.zscover i { font-size: 26px; font-size: 2.6rem; color: #fff; margin-top: 61%; display: block; }

.zsbox span { font-size: 16px; font-size: 1.6rem; color: #333333; display: block; margin-top: 22px; line-height: 30px; }

.zsbox:hover .zscover { top: 0; }

.ms_ye { position: relative; margin-top: 47px; text-align: center; }

.ms_ye a { display: inline-block; width: 32px; line-height: 32px; text-align: center; margin-left: 4px; margin-right: 3px; font-size: 16px; font-size: 1.6rem; background: #fff; color: #333333; border: 1px solid #e5e5e5; }

.ms_sx { width: auto !important; padding: 0 15px !important; }

.ms_sx:hover { color: #fff !important; }

.ms_ye .ms_current_ye { color: #fff; border: 1px solid #0759a2; background: #0759a2; }

.ms_ye a:hover { color: #fff; border: 1px solid #0759a2; background: #0759a2; }


@media screen and (max-width:1219px) {
	.zsbox{margin-left: 30px;width: 222px;}
}
@media screen and (max-width:999px) {
	.zsbox{width: 23%;margin: 0 1%;margin-bottom: 30px;}
	.zscover{display: none;}
	.zsbox span{margin-top: 12px;}
	.ms_ye{margin-top: 30px;}
	.ms_ye a{margin-bottom: 10px;}
}
@media screen and (max-width:700px) {
	.zsbox{width: 31.333%;}
}
@media screen and (max-width:500px) {
	.zsbox{width: 48%;}
}


/***************  about3  ***************/
.lunboa { padding-bottom: 22px; }

.lunboa .ban .ban2 ul li { position: relative; }

.lunboa .ban .ban2 ul li .mzz { position: absolute; bottom: 0; left: 0; width: 100%; display: block; text-align: center; height: 32px; color: #fff; line-height: 32px; background: rgba(0, 0, 0, 0.7); }

.lunboa .ban .ban2 ul li .mzz a { width: 100%; }

.lunboa { position: relative; }

img { border: 0; }

/*1*/
.ban { width: 998px; height: 827px; position: relative; /*overflow:hidden;*/ margin: 0px auto 0 auto; }

.ban2 { width: 998px; height: 651px; position: relative; overflow: hidden; margin: 0 auto; right: 0px; box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.3); }

.ban2 ul { position: absolute; left: 0; top: 0; }

.ban2 ul li { width: 974px; height: 630px; padding-left: 12px; padding-right:12px; padding-top: 12px; padding-bottom: 12px; background: #fff; }

.prev { float: left; cursor: pointer; }

/*2*/
.num { height: 158px; overflow: hidden; width: 998px; position: relative; float: left; }

.min_pic { padding-top: 16px; }

.num ul { position: absolute; left: 0; top: 0; }

.num ul li { width: 230px; height: 161px; margin-right: 25px; padding: 0px; padding-top: 9px; }

.num ul li a { display: block; background: transparent; padding: 3px; padding-right: 2px; }

.num ul li a img { width: 224px; height: 143px; display: block; }

.num ul li.on a { background: #333333; }

.num ul li.on { background: url(../images/sjt.png) no-repeat center top; }

.prev_btn1 { width: 16px; text-align: center; height: 21px; margin-top: 47px; margin-right: 30px; cursor: pointer; float: left; }

.next_btn1 { width: 16px; text-align: center; height: 21px; margin-top: 47px; cursor: pointer; float: right; }

.prev1 { position: absolute; top: 295px; left: -85px; z-index: 9; cursor: pointer; }

.next1 { position: absolute; top: 295px; right: -85px; z-index: 9; cursor: pointer; }

.prev1 i, .next1 i { font-size: 50px; font-size: 5rem; color: #333; }

.prev1 i:hover, .next1 i:hover { color: #333333; }

.mhc { z-index: 99999; background: #000; width: 100%; opacity: 0.5; -moz-opacity: 0.5; filter: alpha(Opacity=50); position: fixed; left: 0; top: 0; display: none; }

.tukuang { display: block; position: relative;}

.tukuang img { display: block; }

.lunboa { padding-bottom: 0px; padding-top: 7px; }

.lunboa .ban .ban2 ul li { position: relative; }

.lunboa .ban .ban2 ul li .mzz { position: absolute; bottom: 0; left: 0; width: 100%; display: block; text-align: center; height: 44px; color: #fff; line-height: 44px; background: rgba(0, 0, 0, 0.5); font-size:18px;font-size:1.8rem; }

.lunboa .ban .ban2 ul li .mzz a { width: 100%; }

.xiaoping { display: none; }

.xiaoping li { width: 48%; margin: 0 1%; margin-bottom: 20px; float: left; }

.xiaoping li img { width: 100%; max-width: 854px; max-height: 617px; }

.xiaoping li p { text-align: center; margin-top: 10px; }

@media screen and (max-width: 1219px) { .lunboa { display: none; }
  .xiaoping { display: block;}}

/**********   product1   **********/
.pfl{margin-bottom: 53px;}
.pfl li{line-height: 30px;padding-top: 5px;padding-bottom: 5px;border-bottom: 2px solid #e3e3e3;}
.pfl li a{font-size:16px;font-size:1.6rem;}
.pfl li div a{line-height: 36px;display: inline-block;min-width: 120px;box-sizing: border-box;padding: 0 10px;margin-right: 15px;text-align: center;}
.pfl li div{overflow: hidden;}
.pfl li span{display: inline-block;min-width: 150px;margin-right: 10px;float: left;}
.pfl li span a{font-size:18px;font-size:1.8rem;}
.pfl li div a:hover{background: #258cdb;color: #fff;}
.pfl li div a.current{background: #258cdb;color: #fff;}

.pbox{width: 282px;float: left;margin-left: 24px;text-align: center;margin-bottom: 40px}
.ptu{display: block;border: 1px solid #cccccc;}
.ptu img{display: block;width: 100%;max-width: 486px;max-height: 370px;}
.pp1{text-align: center;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-top: 26px;}
.pp1 input{cursor: pointer;margin-right: 12px;}
.pp1 a{font-size:16px;font-size:1.6rem;}
.pp1 a:hover{color: #258cdb;}

.xiangshang{display: inline-block;padding-right: 30px;background: url(../images/xs.jpg) no-repeat right 6px;}
.xiangshang input{min-width: 168px;height: 34px;line-height: 34px;text-align: center;color: #ffffff;cursor: pointer;background: #0759a2;}
.xiangshang input:hover{background: #333;}

.product1 .ms_ye{margin-top: 53px;}

@media screen and (max-width:1219px) {
    .pbox{width: 230px;margin-left: 20px;}
}
@media screen and (max-width:999px) {
    .pbox{width: 23%;margin: 0 1%;margin-bottom: 30px;}
}
@media screen and (max-width:767px) {
	.pbox{width: 31.333%;}
	.pfl{margin-bottom: 35px;}
	.pp1 a{font-size:14px;font-size:1.4rem;}
	.pfl li a{font-size:14px;font-size:1.4rem;}
	.pfl li span a{font-size:16px;font-size:1.6rem;}
	.pfl li span{min-width: 120px;}
	.product1 .ms_ye{margin-top: 30px;}
}
@media screen and (max-width:450px) {
	.pbox{width: 48%;margin-bottom: 20px;}
	.pfl li span{float: none;display: block;margin: 0;}
	.pfl li div a{margin-right: 3px;}
	.pp1{margin-top: 20px;}
}

/************  product2  *************/
/*-------------3-2-product start-----------------------------------------------------------*/
.m-prob1 .prob-pic { width: 488px; float: left; }

.m-prob1 .prob-pic-big { position: relative; -moz-box-sizing: border-box; box-sizing: border-box; border: 1px solid #cccccc;}

.m-prob1 .prob-pic-big-img { display: block; line-height: 0; font-size: 0; }

.m-prob1 .prob-pic-big-img .zoomPad { float: none; z-index: 8; }

.m-prob1 .zoomWrapperImage img { max-width: 1200px; }

.m-prob1 .prob-pic-small { position: relative; margin-top: 73px; padding: 0 46px; overflow: hidden; }

.m-prob1 .prob-pic-btn { position: absolute; top: 50%; margin-top: -18px; display: block; width: 30px; height: 30px; line-height: 30px; text-align: center; background: #999999; border-radius: 5px; background-repeat: no-repeat; background-position: center; background-size: 100% 100%; }

.m-prob1 .prob-pic-btn i { color: #fff; }

.m-prob1 .prob-pic-btn:hover { background: #333333; }

.m-prob1 .prob-pic-btn.prev { left: 0; }

.m-prob1 .prob-pic-btn.next { right: 0; }

.m-prob1 .prob-pic-list { overflow: hidden; }

.m-prob1 .prob-pic-list ul { width: 1000%; overflow: hidden; }

.m-prob1 .prob-pic-list ul li { position: relative; float: left; margin-right: 0.2%; width: 1.84%; }

.m-prob1 .prob-pic-item .item-pic { overflow: inherit; -moz-box-sizing: border-box; box-sizing: border-box; border: 1px solid #cccccc;display: block;}

.m-prob1 .prob-pic-item .item-pic img { display: block; width: 100%; }

.m-prob1 .prob-pic-item .item-pic:hover img { -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); transform: scale(1); }

@media screen and (max-width: 1219px) { .zoomPad { cursor: default !important; }
  .zoomPup { display: none !important; }
  .zoomWindow { display: none !important; }
  .zoomPreload { display: none !important; }
  .m-prob1 .prob-pic { float: none; margin: 0 auto; } }

@media screen and (max-width: 767px) { .m-prob1 .prob-pic { width: 100%; }
  .m-prob1 .prob-pic-big { display: none; }
  .m-prob1 .prob-pic-small { margin-top: 0; padding: 0 40px; }
  .m-prob1 .prob-pic-list ul li { margin-right: .2%; width: 3.2%; }
  .m-prob1 .prob-pic-item .item-pic:before { display: none !important; } }

@media screen and (max-width: 600px) { .m-prob1 .prob-pic-list ul li { margin-right: .2%; width: 4.9%; } }

@media screen and (max-width: 480px) { .m-prob1 .prob-pic-list ul li { margin-right: 0%; width: 10%; } }

/* pro-info */
.pro-info2 { display: none; }

.pro-info { width: 675px; overflow: hidden; padding-top: 38px; }

.pro-info .pro-title { font-size: 20px; font-size: 2rem; line-height: 38px; font-weight: bold;padding-bottom: 9px;border-bottom: 1px solid #838281;}

.pro-info .pro-desc { line-height: 24px; margin: 30px 0 48px; color: #555555; max-width: 537px;}

.pro-info .pro-desc p, .pro-info .pro-desc span { color: #555555; }

.pro-info .pro-desc img, .pro-info .pro-desc iframe { max-width: 100%; }

.pro-btn { display: inline-block; width: 150px; height: 38px; line-height: 36px; border: 1px solid #333333; font-size: 14px; font-size: 1.4rem; text-align: center; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-box-sizing: border-box;margin-top: 1px;padding: 0 10px;border-radius: 5px;}

.pro-btn-send { margin-right: 39px; }

.pro-btns{margin-top: 53px;}

.pro-btn-send, .pro-btn-next:hover { border-color: #164a95; background-color: #fff; color: #164a95; }

.pro-btn-next, .pro-btn-send:hover { border-color: #164a95; background-color: #164a95; color: #fff; }

/* pro-share start */
.pro-share { height: 26px; font-size: 0; letter-spacing: 0; }

.pro-share .share-title { margin-right: 11px; display: inline-block; *display: inline; *zoom: 1; line-height: 26px; font-size: 14px; font-size: 1.4rem; color: #999; }

.pro-share .share-pic { display: inline-block; *display: inline; *zoom: 1; vertical-align: top; height: 26px; }

.product2 .m-fixnav { margin-top: 71px; }

.chanpinx { line-height: 26px; color: #333333; font-size: 16px; font-size: 1.6rem; }

.chanpinx li, .chanpinx span, .chanpinx b, .chanpinx p { color: #333333; }

.chanpinx a { color: #143e87; }

.chanpinx img { max-width: 100%; }

.chanpinx iframe { max-width: 100%; }

.des{background: #f1f1f1;margin-top: 61px;margin-bottom: 40px;}
.des2{margin-top: 75px;margin-bottom: 86px;}
.des span,.des a{display: inline-block;line-height: 46px;border-top: 4px solid #999999;padding: 0 25px;background: #0759a2;font-size:24px;font-size:2.4rem;color: #fff;}

.fyphang{padding-bottom: 61px;background: url(../images/yinying.png) no-repeat center bottom;margin-bottom: 13px;}
.fypnr{padding-top: 78px;}
.fypnr strong{font-size: 30px;font-size: 3rem;color: #0759a2;line-height: 36px;display: block;margin-bottom: 10px;}
.fypnr p{font-size:16px;font-size:1.6rem;line-height: 30px;}
.fyphang1 .fyptu{margin-left: 40px;}
.fyphang2 .fyptu{margin-right: 35px;}
.fyptu img{display: block;width: 100%;max-width: 506px;max-height: 285px;}

@media screen and (max-width: 767px) { 
	.pro-share .share-title { font-size: 12px; font-size: 1.2rem; } }
/* pro-share end */
@media screen and (max-width: 1219px) { .pro-info1 { display: none; }
  .pro-info2 { display: block; }
  .pro-info { width: 100%; } 
  .fypnr strong{font-size:24px;font-size:2.4rem;}
  .fypnr p{font-size:14px;font-size:1.4rem;}
  .fypnr{padding-top: 30px;}
  .pro-info .pro-desc{max-width: 100%;}
  .fyphang .fyptu{width: 400px;}
  .fyphang1 .fyptu{margin-left: 25px;}
  .fyphang2 .fyptu{margin-right: 25px;}
  .des2{margin-bottom: 50px;margin-top: 50px;}
}
@media screen and (max-width: 999px) { 
	.fypnr strong{font-size:20px;font-size:2rem;}
	.fypnr{padding-top: 15px;}
	.fyphang .fyptu{width: 350px;}
}
@media screen and (max-width: 767px) { .pro-info .pro-title { font-size: 20px; font-size: 2rem; line-height: 30px; padding: 0px 0 10px; }
  .pro-info .pro-desc { font-size: 14px; font-size: 1.4rem; line-height: 24px; margin: 10px 0; }
  .pro-btn { font-size: 14px; font-size: 1.4rem; height: 32px; line-height: 30px; } 
  .pro-btns{margin-top: 35px;}
  .des{margin-top: 40px;}
  .fyphang .fyptu{float: none;width: auto;max-width: 506px;margin: 0 auto;}
  .des span, .des a{font-size:20px;font-size:2rem;}
  .des2{margin-bottom: 35px;margin-top: 25px;}
}
@media screen and (max-width: 500px) {
	.des{margin-bottom: 25px;}
	.des span, .des a{font-size:16px;font-size:1.6rem;}
}
@media screen and (max-width: 400px) { .pro-btn { width: 48%; }
  .pro-btn-send { margin-right: 2%; } 
  .pro-info .pro-title{font-size:16px;font-size:1.6rem;}
  .pro-info{padding-top: 25px;}
}


/**********  news1  **********/
.fynbox { width: 370px; float: left; margin-left: 45px; margin-bottom: 41px; }

.fyncon { position: relative; overflow: hidden; }

.fyncon img { display: block; width: 100%; max-width: 370px; max-height: 269px; }

.fyncover { position: absolute; left: 0; top: -100%; width: 100%; height: 100%; background: url(../images/sypcover.png); }

.fyncover span { position: absolute; width: 148px; height: 54px; line-height: 54px; text-align: center;color: #fff; background: #0759a2; font-size:16px;font-size:1.6rem; left: 50%; margin-left: -74px; top: 50%; margin-top: -27px; }

.fynp1 { line-height: 24px; display: block; font-size: 24px; font-size: 2.4rem; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; margin-top: 23px; }

.fynp2 { display: block; line-height: 24px; height: 48px; overflow: hidden; font-size:16px;font-size:1.6rem;margin-top: 16px;}

.fynp3 { display: block; color: #999999; margin-top: 22px;font-size:16px;font-size:1.6rem;}

.fynbox:hover .fyncover { top: 0; }

.fynp1:hover, .fynp2:hover { color: #0759a2; }

.news1 .ms_ye{margin-top: 41px;}

@media screen and (max-width: 1219px) { .fynbox { margin-left: 20px; width: 313px; } }

@media screen and (max-width: 999px) { .fynbox { width: 31.333%; margin: 0 1%; margin-bottom: 30px; }
  .fynp1 { font-size: 16px; font-size: 1.6rem; margin-top: 16px;}
  .fynp2{font-size:14px;font-size:1.4rem;margin-top: 10px;}
  .fyncover { display: none; } 
  .fynp3{font-size:14px;font-size:1.4rem;}
}

@media screen and (max-width: 767px) { .fynbox { width: 48%; } }

@media screen and (max-width: 400px) { .fynbox { float: none; width: auto; max-width: 370px; margin: 0 auto; margin-bottom: 20px; } }


/**********  news2  **********/
.news2 h1 {font-size:18px;font-size:1.8rem; font-weight: bold; color: #333333; margin-bottom: 31px; text-align: center; line-height: 30px; }

.kejisj { text-align: center; }

.kejisj span { line-height: 30px; display: inline-block; color: #333; font-size: 16px; font-size: 1.6rem; }

.kejisj i { font-size:16px;font-size:1.6rem; color: #4f4f4f; margin-right: 17px; }

.nshare { display: inline-block; margin-left: 35px; }

.nshare1 { display: inline-block; font-size: 16px; font-size: 1.6rem; color: #666666; margin-right: 13px; }

.nshare2 { display: inline-block; }

.nshare2 img { vertical-align: middle; }

.nex { line-height: 26px; font-size: 16px; font-size: 1.6rem; padding-top: 33px; padding-bottom: 27px; margin-bottom: 47px; background: url(../images/nxu.png) repeat-x center bottom; color: #333; }

.nex span, .nex li, .nex p, .nex b { color: #333; }

.nex img, .nex iframe { max-width: 100%; }

.xwsx { line-height: 28px; margin-top: 18px; }

.xwsx span { display: inline-block; width: 77px; height: 24px; line-height: 24px; border: 1px solid #0a0a0a; text-align: center; margin-right: 17px; color: #333333; }

.xwsx a { font-size: 16px; font-size: 1.6rem; color: #333333; }

.xwsx a:hover { color: #0759a2; }

@media screen and (max-width: 767px) { .nex{margin-bottom: 30px;}}

/*************  marketing  **************/
.mbox{display: inline-block;vertical-align: top;width: 377px;margin-left: 34px;margin-bottom: 27px;text-align: center;}
.marketing{font-size: 0;letter-spacing: 0;}
.mbox img{display: block;width: 100%;max-width: 377px;max-height: 268px;}
.mbox p{font-size:16px;font-size:1.6rem;line-height: 26px;margin-top: 20px;}

@media screen and (max-width: 1219px) { 
	.mbox{margin-left: 25px;width: 310px;}
}
@media screen and (max-width: 999px) { 
	.mbox{width: 31.333%;margin: 0 1%;margin-bottom: 30px;}
}
@media screen and (max-width: 700px) { 
	.mbox{width: 48%;}
}
@media screen and (max-width: 500px) { 
	.mbox p{font-size:14px;font-size:1.4rem;margin-top: 15px;}
	.mbox{margin-bottom: 20px;}
}


















@media screen and (max-width: 1219px) { 

}
@media screen and (max-width: 999px) { 
	
}
@media screen and (max-width: 767px) { 
	
}
/***********  xiala  ***********/
.yiji{position: absolute;z-index: 9999;width: 163px;background: #fff;top: -9999px;left: 0;}
.yiji li{position: relative;}
.yiji li a{display: block;color: #333;line-height: 23px;padding-top: 10px;padding-bottom: 10px;padding-left: 18px;padding-right: 10px;font-size:16px;font-size:1.6rem;}
.yiji li:hover>a{background:#258cdb;color: #fff;}

.erji{position: absolute;left: 100%;top: -999px;width: 196px;background: #fff;}
.navlist>li:hover .yiji{top: 100%;}
.yiji>li:hover .erji{top: 0px;}

@media screen and (max-width:999px) {
    .yiji{display: none;}
}


.ce { position: fixed; right: 0px; top: 30%; width: 68px; text-align: center; z-index: 9999999999; }

.ce li { height: 68px; line-height: 68px; cursor: pointer; margin-bottom: 2px; -webkit-transition: 0.5s; -o-transition: 0.5s; -moz-transition: 0.5s; transition: 0.5s; border-radius: 5px; }

.ce li a { display: block; height: 68px; }

.ce li i { font-size: 30px; font-size: 3rem; color: #fff; }

.ce1 { background: #378caa; }

.ce2 { background: #33b9f1; }

.ce3 { background: #3174e2; }

.ce4 { background: #1fa928; }

.ce .ce4 .ewm { position: absolute; bottom: 0; left: 125px; max-width: 120px; width: 120px; height: 120px; }

.ce .ce4:hover .ewm { left: -125px; }

@media screen and (max-width: 767px) { .ce { bottom: 0; top: auto; width: 100%; }
  .ce li { width: 25%; margin: 0; float: left; height: 40px; line-height: 40px; border-radius: 0; border-radius: 0; box-shadow: none; }
  .ce .ce4 .ewm { position: absolute; bottom: 50px; left: 80%; margin-left: -60px; display: none; }
  .ce .ce4:hover .ewm { left: 80%; }
  .ce li i { font-size: 24px; font-size: 2.4rem; } }


.hdtop{position: fixed;right: 20px;bottom: -100px;transition: 1s;cursor: pointer;z-index: 99999;width: 59px;height: 59px;line-height: 59px;text-align: center;background: #0759a2;border: 1px solid #33288e;}
.hdtop i{color: #fff;font-size: 28px;font-size: 2.8rem;}
@media screen and (max-width: 767px) { 
	.hdtop{width: 44px;height: 44px;line-height: 44px;}
	.hdtop i{font-size:20px;font-size:2rem;}
}



.preloader {
	background: #000;
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 99999;
}
.la-ball-circus, .la-ball-circus>div {
	position: relative;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}
.la-ball-circus {
	display: block;
	font-size: 0;
	color: #fff
}
.la-ball-circus.la-dark {
	color: #FFF
}
.la-ball-circus>div {
	display: inline-block;
	float: none;
	background-color: currentColor;
	border: 0 solid currentColor
}
.la-ball-circus {
	width: 16px;
	height: 16px
}
.la-ball-circus>div {
	position: absolute;
	top: 0;
	left: -100%;
	display: block;
	width: 16px;
	width: 100%;
	height: 16px;
	height: 100%;
	border-radius: 100%;
	opacity: .5;
	-webkit-animation: ball-circus-position 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1), ball-circus-size 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1);
	-moz-animation: ball-circus-position 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1), ball-circus-size 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1);
	-o-animation: ball-circus-position 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1), ball-circus-size 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1);
	animation: ball-circus-position 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1), ball-circus-size 2.5s infinite cubic-bezier(0.25, 0, 0.75, 1)
}
.la-ball-circus>div:nth-child(1) {
	-webkit-animation-delay: 0s, -0.5s;
	-moz-animation-delay: 0s, -0.5s;
	-o-animation-delay: 0s, -0.5s;
	animation-delay: 0s, -0.5s
}
.la-ball-circus>div:nth-child(2) {
	-webkit-animation-delay: -0.5s, -1s;
	-moz-animation-delay: -0.5s, -1s;
	-o-animation-delay: -0.5s, -1s;
	animation-delay: -0.5s, -1s
}
.la-ball-circus>div:nth-child(3) {
	-webkit-animation-delay: -1s, -1.5s;
	-moz-animation-delay: -1s, -1.5s;
	-o-animation-delay: -1s, -1.5s;
	animation-delay: -1s, -1.5s
}
.la-ball-circus>div:nth-child(4) {
	-webkit-animation-delay: -1.5s, -2s;
	-moz-animation-delay: -1.5s, -2s;
	-o-animation-delay: -1.5s, -2s;
	animation-delay: -1.5s, -2s
}
.la-ball-circus>div:nth-child(5) {
	-webkit-animation-delay: -2s, -2.5s;
	-moz-animation-delay: -2s, -2.5s;
	-o-animation-delay: -2s, -2.5s;
	animation-delay: -2s, -2.5s
}
.la-ball-circus.la-sm {
	width: 8px;
	height: 8px
}
.la-ball-circus.la-sm>div {
	width: 8px;
	height: 8px
}
.la-ball-circus.la-2x {
	width: 32px;
	height: 32px;
	left: 0;
	top: 0;
	right: 0;
	margin: auto;
	bottom: 0;
	position: absolute;
}
.la-ball-circus.la-2x>div {
	width: 32px;
	height: 32px
}
.la-ball-circus.la-3x {
	width: 48px;
	height: 48px
}
.la-ball-circus.la-3x>div {
	width: 48px;
	height: 48px
}
@-webkit-keyframes ball-circus-position {
50% {
left:100%
}
}
@-moz-keyframes ball-circus-position {
50% {
left:100%
}
}
@-o-keyframes ball-circus-position {
50% {
left:100%
}
}
@keyframes ball-circus-position {
50% {
left:100%
}
}
@-webkit-keyframes ball-circus-size {
50% {
-webkit-transform:scale(0.3, 0.3);
transform:scale(0.3, 0.3)
}
}
@-moz-keyframes ball-circus-size {
50% {
-moz-transform:scale(0.3, 0.3);
transform:scale(0.3, 0.3)
}
}
@-o-keyframes ball-circus-size {
50% {
-o-transform:scale(0.3, 0.3);
transform:scale(0.3, 0.3)
}
}
@keyframes ball-circus-size {
50% {
-webkit-transform:scale(0.3, 0.3);
-moz-transform:scale(0.3, 0.3);
-o-transform:scale(0.3, 0.3);
transform:scale(0.3, 0.3)
}
}
.site-welcome {
	display: none;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 99999;
}
.site-welcome.active {
	display: flex;
}




/*********************fangdaxiaoguo**********************/
#baguetteBox-overlay { display: none; opacity: 0; position: fixed; overflow: hidden; top: 0; left: 0; width: 100%; height: 100%; background-color: #222; background-color: rgba(0, 0, 0, 0.8); -webkit-transition: opacity .5s ease; -o-transition: opacity .5s ease; -moz-transition: opacity .5s ease; transition: opacity .5s ease; z-index: 99999999999999999999999999999999999999; }

#baguetteBox-overlay.visible { opacity: 1; }

#baguetteBox-overlay .full-image { display: inline-block; position: relative; width: 100%; height: 100%; text-align: center; }

#baguetteBox-overlay .full-image figure { display: inline; margin: 0; height: 100%; }

#baguetteBox-overlay .full-image img { display: inline-block; width: auto; height: auto; max-height: 100%; max-width: 100%; vertical-align: middle; -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); }

#baguetteBox-overlay .full-image figcaption { display: block; position: absolute; bottom: 0; width: 100%; text-align: center; line-height: 1.8; color: #ccc; background-color: #000; background-color: rgba(0, 0, 0, 0.6); font-family: Verdana, Geneva, sans-serif; }

#baguetteBox-overlay .full-image:before { content: ""; display: inline-block; height: 50%; width: 1px; margin-right: -1px; }

#baguetteBox-slider { position: absolute; left: 0; top: 0; height: 100%; width: 100%; white-space: nowrap; -webkit-transition: left .4s ease,-webkit-transform .4s ease; transition: left .4s ease,-moz-transform .4s ease; transition: left .4s ease,-webkit-transform .4s ease; -o-transition: left .4s ease,-o-transform .4s ease; -moz-transition: left .4s ease,transform .4s ease,-moz-transform .4s ease; transition: left .4s ease,transform .4s ease; transition: left .4s ease,transform .4s ease,-webkit-transform .4s ease,-moz-transform .4s ease,-o-transform .4s ease; }

#baguetteBox-slider.bounce-from-right { -webkit-animation: bounceFromRight .4s ease-out; -moz-animation: bounceFromRight .4s ease-out; animation: bounceFromRight .4s ease-out; }

#baguetteBox-slider.bounce-from-left { -webkit-animation: bounceFromLeft .4s ease-out; -moz-animation: bounceFromLeft .4s ease-out; animation: bounceFromLeft .4s ease-out; }

.baguetteBox-button#next-button, .baguetteBox-button#previous-button { top: 50%; top: -webkit-calc(50% - 30px); top: -moz-calc(50% - 30px); top: calc(50% - 30px); width: 44px; height: 60px; }

.baguetteBox-button { position: absolute; cursor: pointer; outline: 0; padding: 0; margin: 0; border: 0; border-radius: 15%; background-color: #323232; background-color: rgba(50, 50, 50, 0.5); color: #ddd; font: 1.6em sans-serif; -webkit-transition: background-color .4s ease; -o-transition: background-color .4s ease; -moz-transition: background-color .4s ease; transition: background-color .4s ease; }

.baguetteBox-button:hover { background-color: rgba(50, 50, 50, 0.9); }

.baguetteBox-button#next-button { right: 2%; }

.baguetteBox-button#previous-button { left: 2%; }

.baguetteBox-button#close-button { top: 20px; right: 2%; right: -webkit-calc(2% + 6px); right: -moz-calc(2% + 6px); right: calc(2% + 6px); width: 30px; height: 30px; }

.baguetteBox-button svg { position: absolute; left: 0; top: 0; }

.spinner { width: 40px; height: 40px; display: inline-block; position: absolute; top: 50%; left: 50%; margin-top: -20px; margin-left: -20px; }

.double-bounce1, .double-bounce2 { width: 100%; height: 100%; border-radius: 50%; background-color: #fff; opacity: .6; position: absolute; top: 0; left: 0; -webkit-animation: bounce 2s infinite ease-in-out; -moz-animation: bounce 2s infinite ease-in-out; animation: bounce 2s infinite ease-in-out; }

.double-bounce2 { -webkit-animation-delay: -1s; -moz-animation-delay: -1s; animation-delay: -1s; }

@-webkit-keyframes bounceFromRight { 0% { margin-left: 0; }
  50% { margin-left: -30px; }
  100% { margin-left: 0; } }

@-moz-keyframes bounceFromRight { 0% { margin-left: 0; }
  50% { margin-left: -30px; }
  100% { margin-left: 0; } }

@keyframes bounceFromRight { 0% { margin-left: 0; }
  50% { margin-left: -30px; }
  100% { margin-left: 0; } }

@-webkit-keyframes bounceFromLeft { 0% { margin-left: 0; }
  50% { margin-left: 30px; }
  100% { margin-left: 0; } }

@-moz-keyframes bounceFromLeft { 0% { margin-left: 0; }
  50% { margin-left: 30px; }
  100% { margin-left: 0; } }

@keyframes bounceFromLeft { 0% { margin-left: 0; }
  50% { margin-left: 30px; }
  100% { margin-left: 0; } }

@-webkit-keyframes bounce { 0%, 100% { -webkit-transform: scale(0);
    transform: scale(0); }
  50% { -webkit-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes bounce { 0%, 100% { -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0); }
  50% { -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1); } }

@keyframes bounce { 0%, 100% { -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  50% { -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@font-face {
	font-family: "POPPINS-BOLD";
	src: url("../fonts/POPPINS-BOLD.woff2") format("woff2"),
			url("../fonts/POPPINS-BOLD.woff") format("woff"),
			url("../fonts/POPPINS-BOLD.ttf") format("truetype"),
			url("../fonts/POPPINS-BOLD.eot") format("embedded-opentype"),
			url("../fonts/POPPINS-BOLD.svg") format("svg");
	font-weight: normal;
	font-style: normal;
	}

	@font-face { font-family: 'PoppinsSemiBold'; src: url("../fonts/PoppinsSemiBold.eot"); src: url("../fonts/PoppinsSemiBold.eot") format("embedded-opentype"), url("../fonts/PoppinsSemiBold.woff2") format("woff2"), url("../fonts/PoppinsSemiBold.woff") format("woff"), url("../fonts/PoppinsSemiBold.ttf") format("truetype"), url("../fonts/PoppinsSemiBold.svg#PoppinsSemiBold") format("svg"); }


/*
font-family: "POPPINS-BOLD";
*/

